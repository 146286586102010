import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    document.getElementById("iban_change_error").style.display = "none";
  }

  get ibanController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("iban-field"),
      "iban"
    );
  }

  submit(event) {
    let validity = this.ibanController.isValid();

    if (!validity) {
      document.getElementById("iban_change_error").style.display = "block";
      event.preventDefault();
    }
  }
}
