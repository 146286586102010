import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["click"];

  initialize() {}

  click(e) {
    const _this = this;

    e.preventDefault();

    let overlay = document.createElement("div");
    overlay.setAttribute(
      "class",
      "Initiative--overlay NoticeOverlay NoticeOverlay--visible"
    );
    document.body.appendChild(overlay);

    let noticeHolder = document.createElement("div");
    noticeHolder.setAttribute(
      "class",
      "Initiative--notice Notice Notice--visible"
    );

    let noticeContent = document.createElement("div");
    noticeContent.setAttribute("class", "Notice__Content");

    noticeHolder.appendChild(noticeContent);
    document.body.appendChild(noticeHolder);

    noticeContent.innerHTML = document.querySelector(
      ".Initiatives__Details__Vote--content"
    ).innerHTML;

    const closeEl = document.querySelector(
      ".Notice__Content .Initiative--cancel"
    );

    closeEl.addEventListener("click", e => {
      e.preventDefault();

      _this.close();
    });
  }

  close() {
    document.querySelector(".Initiative--overlay").remove();
    document.querySelector(".Initiative--notice").remove();
  }
}
