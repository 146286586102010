import IBAN from "iban";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bank_account"];

  initialize() {
    if (this.bank_account) {
      this.validate();
    }
  }

  get bank_account() {
    return this.bank_accountTarget.value;
  }

  isValid() {
    return this.valid;
  }

  validate() {
    if (this.status) this.removeStatus(this.element);

    const pattern = new RegExp("^FI[A-Z0-9 ]*$", "i");
    this.valid =
      IBAN.isValid(this.bank_account) && pattern.test(this.bank_account);
    this.status = this.valid
      ? "TextInput--status--success"
      : "TextInput--status--error";

    this.addStatus(this.element);
  }

  removeStatus(el) {
    el.closest(".FieldGroup")
      .querySelector(".TextInput--status")
      .classList.remove(this.status);
  }

  addStatus(el) {
    el.closest(".FieldGroup")
      .querySelector(".TextInput--status")
      .classList.add(this.status);
  }
}
