// TopBar Login components
//

const topBar = {};

topBar.bindEvents = (clicked, el, selector) => {
  clicked.addEventListener("click", e => {
    e.preventDefault();

    if (el.classList.contains(selector)) {
      el.classList.remove(selector);
    } else {
      el.classList.add(selector);
    }
  });
};
topBar.init = () => {
  const elements = document.querySelectorAll(
    ".TopBar__Container__Navigation__Item__Link"
  );

  if (elements) {
    elements.forEach(el => {
      const parent = el.parentNode;

      if (parent.children && parent.children.length === 2) {
        topBar.bindEvents(
          el,
          el,
          "TopBar__Container__Navigation__Item__Link--open"
        );

        document
          .querySelector(".TopBar__Container__Navigation__Item__Popup__Overlay")
          .addEventListener("click", e => {
            el.classList.remove(
              "TopBar__Container__Navigation__Item__Link--open"
            );
          });
      }
    });
  }

  const toggleNavi = document.querySelector(".TopBar__Container__Toggle");
  const topBarEl = document.querySelector(".TopBar__Placeholder");
  if (toggleNavi !== null && topBarEl !== null) {
    topBar.bindEvents(toggleNavi, topBarEl, "TopBar__Placeholder__Open");
  }
};

window.topBar = topBar;
