import { Controller } from "stimulus";

export default class PostcodesController extends Controller {
  static targets = ["zipcode", "city"];

  search() {
    const value = this.zipcodeTarget.value;
    // Don't search zipcodes if less than 3 digits entered
    if (value.length < 3 || value.length > 5) {
      return;
    }

    fetch(`/api/postcodes/search/${value}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length == 1) {
          this.cityTarget.value = data[0];
        }
      });
  }
}
