// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

let up = true;
let start = 0;
let scrollDown = 0;

const sidenav = {};

const mvNav = {
  scroll: () => {
    if (
      window.pageYOffset + window.outerHeight >
      window.pageYOffset + mvNav.content.dimensions.bottom
    ) {
      mvNav.checkIfBelowBottom();
    } else if (
      up &&
      mvNav.nav.dimensions.bottom <
        window.pageYOffset + mvNav.content.dimensions.bottom
    ) {
      mvNav.checkGoingUp();
    } else if (sidenav.dimensions.top < mvNav.mainnav.dimensions.bottom) {
      mvNav.checkGoingDown();
    }
  },
  setBottom: bottom => {
    mvNav.nav.el.style.top = "auto";
    mvNav.nav.el.style.bottom = bottom + "px";
  },
  checkIfBelowBottom: () => {
    if (
      window.pageYOffset + mvNav.nav.dimensions.height >
      window.pageYOffset + mvNav.content.dimensions.bottom
    ) {
      mvNav.setBottom(window.innerHeight - mvNav.content.dimensions.bottom);
    }
  },
  checkGoingUp: () => {
    if (
      mvNav.nav.dimensions.top >=
      mvNav.mainnav.placeholder.dimensions.height +
        mvNav.mainnav.dimensions.height
    ) {
      if (mvNav.nav.dimensions.top > sidenav.dimensions.top) {
        mvNav.setTop();
      } else {
        mvNav.nav.el.classList.remove("fixed");
        mvNav.nav.el.style.top = "auto";
        mvNav.nav.el.style.bottom = "auto";
      }
    } else {
      mvNav.setTop();
    }
    scrollDown = window.pageYOffset + mvNav.nav.dimensions.bottom;
  },
  checkGoingDown: () => {
    mvNav.nav.el.classList.add("fixed");
    mvNav.nav.el.style.width = sidenav.dimensions.width + "px";
    if (mvNav.nav.dimensions.height > window.outerHeight) {
      let bottom = window.pageYOffset + window.innerHeight - scrollDown;
      if (bottom > 0) bottom = 0;
      mvNav.setBottom(bottom);
    } else {
      mvNav.setBottom(
        window.pageYOffset +
          window.innerHeight -
          (window.pageYOffset +
            mvNav.mainnav.dimensions.height +
            mvNav.nav.dimensions.height)
      );
    }
  },
  setTop: () => {
    mvNav.setBottom(
      window.pageYOffset +
        window.innerHeight -
        (window.pageYOffset +
          mvNav.nav.dimensions.height +
          mvNav.mainnav.placeholder.dimensions.height +
          mvNav.mainnav.dimensions.height)
    );
  },
  init: () => {
    mvNav.nav = {
      el: sidenav.holder.querySelector("nav"),
      dimensions: sidenav.holder.querySelector("nav").getBoundingClientRect()
    };
    mvNav.content = {
      el: document.querySelector(".MemberVoting__Body"),
      dimensions: document
        .querySelector(".MemberVoting__Body")
        .getBoundingClientRect()
    };

    mvNav.mainnav = {
      el: document.querySelectorAll(".NavigationHeader__Container__Content"),
      dimensions: document
        .querySelector(".NavigationHeader__Container__Content")
        .getBoundingClientRect(),
      placeholder: {
        el: document.querySelector(".TopBar__Placeholder"),
        dimensions: document
          .querySelector(".TopBar__Placeholder")
          .getBoundingClientRect()
      }
    };
  }
};

const scroll = function() {
  sidenav.holder = document.querySelector(".MemberVoting__Archive__Sidebar");
  if (sidenav.holder != null) {
    sidenav.dimensions = document
      .querySelector(".MemberVoting__Archive__Sidebar")
      .getBoundingClientRect();

    if (window.pageYOffset > start) up = false;
    else up = true;

    start = window.pageYOffset;

    mvNav.init();

    mvNav.nav.el.style.left = sidenav.dimensions.x + "px";

    mvNav.scroll();
  }
};

window.membervotingarchive = {
  scroll
};
