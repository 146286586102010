import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password", "current_password", "success", "submit"];

  initialize() {}

  toggleSuccess() {
    this.submitTarget.classList.remove("Button--hidden");
    this.successTarget.classList.add("Button--hidden");
  }
}
