// Navigation functionality
//

const navigation = {};

navigation.toggleLanguage = {};

navigation.addEvents = (trigger, el, selector) => {
  trigger.addEventListener("click", e => {
    if (el.classList.contains(selector + "--open")) {
      el.classList.remove(selector + "--open");
    } else {
      el.classList.add(selector + "--open");
    }
  });
};
navigation.toggleLanguage.init = () => {
  const trigger = document.querySelector(
    ".TopBar__Container__Navigation__Item__Link--lang"
  );

  const el = document.querySelector(".LanguageSelector");

  if (trigger !== null) {
    navigation.addEvents(trigger, el, "LanguageSelector");
    document
      .querySelector(".LanguageSelector__Overlay")
      .addEventListener("click", e => {
        el.classList.add("LanguageSelector--open");
      });
  }
};

navigation.actions = {
  setSmall: viewports => {
    const elements = {
      desktop: {
        self: navigation.dtNavEl,
        area: navigation.dtArea,
        offset: navigation.dtAreaOffset
      },
      mobile: {
        self: navigation.mobNavEl,
        area: navigation.mobArea,
        offset: navigation.mobAreaOffset
      }
    };
    viewports.forEach(viewport => {
      const el = elements[viewport];
      if (window.pageYOffset >= el.area.clientHeight + el.offset) {
        if (el.self) {
          el.self.classList.add("small");
        }
      }
    });
  },
  css: (elements, obj) => {
    elements.forEach(el => {
      if (el) {
        el.classList.add(obj.add);
        el.classList.remove(obj.remove);
      }
    });
  },
  moveDown: () => {
    navigation.actions.setSmall(["mobile", "desktop"]);

    if (window.pageYOffset >= navigation.dtAreaOffset) {
      navigation.actions.css([navigation.dtNavEl, navigation.mobNavEl], {
        add: "fixed",
        remove: "up"
      });
      navigation.navigationEl.classList.add("small");
      navigation.navigationEl.classList.add("fixed");
    }
  },
  moveUp: () => {
    navigation.actions.css([navigation.dtNavEl, navigation.mobNavEl], {
      add: "up",
      remove: "small"
    });
    navigation.navigationEl.classList.remove("small");
    navigation.navigationEl.classList.add("fixed");
  }
};

navigation.scroll = () => {
  const action = window.pageYOffset > navigation.start ? "moveDown" : "moveUp";
  navigation.start = window.pageYOffset;

  const topBarEls = document.querySelectorAll(
    ".MemberPortalLogin__Overlay, .TopBar__Container__Navigation__Item__Link--open"
  );

  if (
    navigation.start > 0 &&
    navigation.start + document.documentElement.clientHeight <
      document.body.scrollHeight &&
    topBarEls.length == 0
  ) {
    navigation.actions[action]();
  }
};

navigation.subNavInit = selector => {
  const elements = document.querySelectorAll("." + selector + "--has-children");
  elements.forEach(el => {
    navigation.addEvents(
      el.querySelector("." + selector + "__Arrow"),
      el,
      selector
    );
  });
};

navigation.getElOffsetTop = selector => {
  return document.querySelector(selector) !== null
    ? document.querySelector(selector).offsetTop
    : navigation.navigationEl.offsetTop;
};

navigation.checkEl = selector => {
  return document.querySelector(selector) !== null
    ? document.querySelector(selector)
    : false;
};

navigation.getEl = selector => {
  return document.querySelector(selector) !== null
    ? document.querySelector(selector)
    : navigation.navigationEl;
};

navigation.init = () => {
  navigation.start = 0;
  navigation.navigationEl = document.querySelector(".TopBar");
  navigation.mobAreaOffset = navigation.getElOffsetTop(".MobileNavigation");
  navigation.mobNavEl = navigation.checkEl(".MobileNavigation__Container");
  navigation.mobArea = navigation.getEl(".MobileNavigation__Container");
  navigation.dtAreaOffset = navigation.getElOffsetTop(
    ".NavigationHeader__Placeholder"
  );
  navigation.dtNavEl = navigation.checkEl(".NavigationHeader");
  navigation.dtArea = navigation.getEl(".NavigationHeader");
  navigation.subNavInit("NavigationHeader__Container__Content__Elem");
  navigation.subNavInit("SideBar__Navigation__Item");
  navigation.toggleLanguage.init();
};

window.navigation = navigation;
