// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const password = {};

password.toggle = (passwords, type) => {
  passwords.forEach(el => {
    el.setAttribute("type", type);
  });
};

password.launch = (passwords, el) => {
  setTimeout(() => {
    if (el.checked) {
      password.toggle(passwords, "text");
    } else {
      password.toggle(passwords, "password");
    }
  }, 5);
};

password.init = () => {
  const el = document.querySelector("#show_password");

  if (el) {
    const form = el.closest("form");
    const passwords = form.querySelectorAll('[type="password"]');

    el.addEventListener("change", e => {
      if (e.target.checked) {
        password.toggle(passwords, "text");
      } else {
        password.toggle(passwords, "password");
      }
    });

    password.launch(passwords, el);
  }
};

const validate = {};

validate.status = (() => {
  return document.querySelector(".TextInput--status--error") === null
    ? true
    : false;
})();
validate.toggleErrorText = {
  getEl: (el, check) => {
    if (el.closest(".FieldGroup").querySelector(".InfoText") !== null) {
      el.closest(".FieldGroup")
        .querySelector(".InfoText")
        .classList.remove("ErrorText");
      if (!check) {
        el.closest(".FieldGroup")
          .querySelector(".InfoText")
          .classList.add("ErrorText");
      }
    }

    return el.closest(".FieldGroup").querySelector(".ErrorText");
  },
  do: (check, el) => {
    el = validate.toggleErrorText.getEl(el, check);
    if (el && check) {
      el.classList.remove("ErrorText--visible");
    } else if (el) {
      el.classList.add("ErrorText--visible");
    }
  }
};

validate.statusIcon = {
  true: el => {
    validate.statusIcon.set(
      el,
      ["TextInput--status--success"],
      ["TextInput--status--error"]
    );
  },
  false: el => {
    validate.statusIcon.set(
      el,
      ["TextInput--status--error"],
      ["TextInput--status--success"]
    );
  },
  reset: el => {
    validate.statusIcon.set(
      el,
      [],
      ["TextInput--status--success", "TextInput--status--error"]
    );
  },
  set: (el, add, remove) => {
    el = el.closest(".TextInput--status");

    if (el) {
      add.forEach((status, i) => {
        el.closest(".TextInput--status").classList.add(status);
      });
      remove.forEach((status, i) => {
        el.closest(".TextInput--status").classList.remove(status);
      });
    }
  }
};

validate.toggleFormState = (check, el) => {
  const form = el.closest("form");
  if (check && form) {
    form.querySelector('input[type="submit"]').removeAttribute("disabled");
  } else if (form) {
    form.querySelector('input[type="submit"]').setAttribute("disabled", true);
  }
  validate.inputs[el.name] = setTimeout(() => {
    validate.statusIcon[String(check)](el);
    validate.toggleErrorText.do(check, el);
  }, 500);
};

validate.check = {
  "activation[userid]": el => {
    return isNumeric(el.value) && el.value.length <= 20 ? true : false;
  },
  "activation[date_of_birth]": el => {
    return isNumeric(el.value) && el.value.length > 7 ? true : false;
  }
};

validate.email = el => {
  let check = true;

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(el.value)) {
    check = false;
  }

  validate.toggleFormState(check, el);

  return check;
};

validate.password = el => {
  let check = true;

  if (el.value.length < 8) {
    check = false;
  }

  validate.toggleFormState(check, el);

  return check;
};

validate.checkInit = el => {
  if (!validate.status && el.value === "") {
    return true;
  }

  return false;
};

validate.checkValue = el => {
  let check = validate.check[el.name];

  if (typeof check !== "undefined" && !check(el)) {
    return false;
  }

  return true;
};

validate.validateForm = form => {
  const submit = form.querySelectorAll('[type="submit"]')[0];

  submit.removeAttribute("disabled");
  form.querySelectorAll("input").forEach((el, i) => {
    if (validate.checkInit(el)) {
      return true;
    } else if (!validate.checkValue(el)) {
      submit.setAttribute("disabled", "disabled");
    }
  });

  validate.status = true;
};

validate.events = {
  blur: false
};
validate.addEvent = {
  input: (el, form) => {
    el.addEventListener("input", e => {
      validate.events.blur = true;
      clearTimeout(validate.inputs[el.name]);
      validate.statusIcon.reset(el);
      validate.validateForm(form);
    });
  },
  blur: (el, form) => {
    if (!validate.events.blur) {
      el.addEventListener("blur", e => {
        clearTimeout(validate.inputs[el.name]);
        validate.statusIcon.reset(el);
        validate.validateForm(form);
      });
    }
  }
};

validate.inputs = {};

validate.init = form => {
  if (form.getAttribute("data-controller") == null) {
    const inputsArr = form.querySelectorAll("input");

    inputsArr.forEach((el, i) => {
      validate.addEvent.input(el, form);
      validate.addEvent.blur(el, form);
    });

    if (!validate.status) {
      validate.validateForm(form);
    }
  }
};

window.validate = validate;

const validations = {};

validations.init = () => {
  validations.newUser();
};

validations.newUser = () => {
  const form = document.querySelector("#new_user");

  if (form) {
    setTimeout(() => {
      validate.init(form);
    }, 10);
  }
};

document.addEventListener("turbolinks:before-cache", function() {
  const buttons = document.querySelectorAll(".Button--submit");

  buttons.forEach((el, i) => {
    el.classList.remove("Button--hidden");
  });

  const loaderButtons = document.querySelectorAll(".Button--loading");

  loaderButtons.forEach((el, i) => {
    el.classList.add("Button--hidden");
  });
});

const toggleSubmit = el => {
  if (el.querySelector(".Button--submit"))
    el.querySelector(".Button--submit").classList.add("Button--hidden");
  if (el.querySelector(".Button--loading"))
    el.querySelector(".Button--loading").classList.remove("Button--hidden");
};

function setMobileDevice() {
  if (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  ) {
    document.body.classList.add("touch");
  }
}

document.addEventListener("turbolinks:load", () => {
  setMobileDevice();

  const forms = document.querySelectorAll("form");

  forms.forEach((el, i) => {
    el.addEventListener("submit", e => {
      toggleSubmit(el);
    });
  });
  password.init();
  window.topBar.init();
  validations.init();
  window.userBirthday.init();
  window.userId.init();
  window.navigation.init();
  window.card_notice.init();

  window.onscroll = () => {
    navigation.scroll();
    membervotingarchive.scroll();
  };

  window.onload = () => {
    membervotingarchive.scroll();
  };

  const hash = location.hash;
  if (hash) {
    const target = document.getElementById(hash.replace("#", ""));
    if (target) {
      window.scrollTo(0, target.offsetTop);
    }
  }
});
