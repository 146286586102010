const userBirthday = {};

userBirthday.setValue = e => {
  const parent = e.target.closest(".TextInput");
  const el = parent.querySelector("#activation_date_of_birth");
  const values = document.querySelectorAll(".TextInput--birthday--input");
  let value = "";

  values.forEach(el => {
    value += el.value;
  });

  el.value = value;

  registrationView.validate.input(el);
};

userBirthday.setInput = (el, prev, next) => {
  el.addEventListener("input", e => {
    e.target.value = e.target.value.replace(/\D/g, '');
    const forward = e.target.value.length >= 2 ? true : false;

    if (forward) {
      userBirthday.stepForward(e, next);
    } else {
      userBirthday.stepBack(e, prev);
    }

    userBirthday.setValue(e);
  });
};

userBirthday.setBackspace = (el, prev) => {
  el.addEventListener("keydown", e => {
    if (e.keyCode === 8 && e.target.value.length === 0 && prev) {
      prev.focus();
      prev.value = "";
    }
  });
};

userBirthday.setEvents = (el, prev, next) => {
  userBirthday.setInput(el, prev, next);
  userBirthday.setBackspace(el, prev);
};

userBirthday.step = (target, val) => {
  target.focus();
  if (val) target.value = val;
  else target.value = target.value;
};

userBirthday.stepForward = (e, next) => {
  const val =
    e.target.value.length > 2
      ? String(e.target.value).substr(
          e.target.value.length - 1,
          e.target.value.length
        )
      : false;
  e.target.value = !next
    ? e.target.value.substr(0, 4)
    : e.target.value.substr(0, 2);

  if (next && e.target.value.length === 2) {
    userBirthday.step(next, val);
  }
};

userBirthday.stepBack = (e, prev) => {
  if (prev && e.target.value.length !== 1) {
    userBirthday.step(prev);
  }
};
userBirthday.setDefaultVal = (hiddenEl, inputElements) => {
  if (hiddenEl && hiddenEl.value !== "") {
    let birthdayArr = [];

    birthdayArr[0] = hiddenEl.value.substr(0, 2);
    birthdayArr[1] = hiddenEl.value.substr(2, 2);
    birthdayArr[2] = hiddenEl.value.substr(4, 4);

    inputElements.forEach((el, i) => {
      el.value = birthdayArr[i];
    });
  }
};

userBirthday.datePicker = () => {
  const datePicker = document.querySelector(".TextInput--birthday .DatePicker");
  const birthdayEl = document.querySelector(
    ".TextInput--birthday #activation_date_of_birth"
  );
  let birthday = [];

  const elements = document.querySelectorAll(
    ".TextInput--birthday--holder input"
  );

  if (datePicker) {
    datePicker.addEventListener("input", e => {
      datePicker.value.split("-").forEach((val, i) => {
        if (i == 0) birthday[2] = val;
        else if (i == 2) birthday[0] = val;
        else birthday[1] = val;
      });

      birthdayEl.value = birthday.join("");
      datePicker.value = birthday.join("");

      elements.forEach((el, i) => {
        el.value = birthday[i];
      });
    });
  }
};
userBirthday.init = () => {
  userBirthday.datePicker();

  const elements = document.querySelectorAll(".TextInput--birthday--holder");

  if (elements.length) {
    const el = elements[0];
    const inputElements = el.querySelectorAll("input");
    const input = el
      .closest(".FieldGroup")
      .querySelector("#activation_date_of_birth");

    userBirthday.setDefaultVal(input, inputElements);
    inputElements.forEach((el, i) => {
      const prev = inputElements[i - 1] ? inputElements[i - 1] : false;
      const next = inputElements[i + 1] ? inputElements[i + 1] : false;

      userBirthday.setEvents(el, prev, next);
    });

    if (
      input.value !== "" &&
      input.closest(".TextInput--status--error") === null
    )
      registrationView.validate.blur(input);
  }
};

const userId = {};
userId.blur = false;
userId.init = () => {
  const el = document.querySelector("#activation_userid");
  if (el) {
    el.addEventListener("input", e => {
      el.value = el.value.substr(0, 20);
      registrationView.validate.input(el);
    });

    if (el.value !== "" && el.closest(".TextInput--status--error") === null)
      registrationView.validate.blur(el);
  }
};

const registrationView = {
  inputs: {},
  validate: {
    input: el => {
      if (!userBirthday.blur) {
        el.addEventListener("blur", e => {
          registrationView.validate.blur(el);
        });
        userBirthday.blur = true;
      }
      clearTimeout(window.validate.inputs[el.name]);
      validate.toggleFormState(validate.check[el.name](el), el);
    },
    blur: el => {
      clearTimeout(window.validate.inputs[el.name]);
      validate.toggleFormState(validate.check[el.name](el), el);
    }
  }
};

window.userBirthday = userBirthday;
window.userId = userId;
