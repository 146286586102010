import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["amount"];

  connect() {
    if (this.amount.length > 0) {
      this.validate();
    }
  }

  get amount() {
    return this.amountTarget.value;
  }

  isValid() {
    return this.valid;
  }

  validate() {
    if (this.status) this.removeStatus(this.element);

    const max = this.data.get("total");
    const pattern = new RegExp("^[0-9]+$");
    this.valid =
      pattern.test(this.amount) &&
      (this.amount >= 1 && this.amount <= parseInt(max));

    this.status = this.valid
      ? "TextInput--status--success"
      : "TextInput--status--error";

    this.clearBackEndErrors(this.element, this.valid);
    this.addStatus(this.element);
    this.updateSubscriptionPrice(this.valid);
  }

  removeStatus(el) {
    el.closest(".FieldGroup")
      .querySelectorAll(".TextInput--status")
      .forEach(node => node.classList.remove(this.status), this);
  }

  addStatus(el) {
    el.closest(".FieldGroup")
      .querySelectorAll(".TextInput--status")
      .forEach(node => node.classList.add(this.status), this);
  }

  clearBackEndErrors(el, valid) {
    const be_error = el
      .closest(".FieldGroup")
      .querySelector(".TextInput--status .TextInput--status");
    if (be_error) {
      el
        .closest(".FieldGroup")
        .querySelector(".TextInput--status .ErrorText").style["display"] =
        "none";
      if (valid) {
        const beErrorText = el
          .closest(".FieldGroup")
          .querySelector(".ErrorText--amount");

        if (beErrorText) {
          beErrorText.remove("ErrorText--visible");
        }
      }
    }
  }

  updateSubscriptionPrice(valid) {
    const value = valid ? this.data.get("value") * this.amount : 0;
    document.getElementById("profit_share_price").innerHTML = value;

    let checkbox_amount = document.getElementById("amount_to_sub");
    let checkbox_eur_amount = document.getElementById("eur_amount_to_sub");

    if (checkbox_amount != undefined && checkbox_eur_amount != undefined) {
      checkbox_amount.innerHTML = this.amount;
      checkbox_eur_amount.innerHTML = value;
    }
  }
}
