import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option"];

  prevent(e) {
    let count = 0;
    for (var i = 0; i < this.optionTargets.length; i++) {
      if (this.optionTargets[i].checked) {
        count++;
      }
    }

    if (
      count >
      parseInt(
        document.querySelector(".MemberVoting__Form").getAttribute("data-max")
      )
    )
      e.target.checked = false;
  }

  validate(e) {
    this.prevent(e);
  }
}
