import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "phonenumber",
    "address",
    "zipcode",
    "city",
    "email",
    "password",
    "membercard",
    "newsletter",
    "passwordvisibility"
  ];

  initialize() {
    this.togglePasswordVisibility(this.passwordvisibilityTarget);

    let emptyInit = this.emptyInit();

    if (!emptyInit) {
      this.validatePhone(this.phonenumberTarget);
      this.validateEmpty(this.addressTarget);
      this.validateEmpty(this.zipcodeTarget);
      this.validateEmpty(this.cityTarget);
      this.validateEmail(this.emailTarget);
      this.validatePassword(this.passwordTarget);
    }
  }

  emptyInit() {
    let emptyInit = true;
    let targets = [
      "phonenumber",
      "address",
      "zipcode",
      "city",
      "email",
      "password"
    ];

    for (let key in targets) {
      let target = targets[key];

      if (this[target + "Target"].value != "") {
        emptyInit = false;
      }
    }

    return emptyInit;
  }

  validateEmpty(e) {
    e = this.setTarget(e);

    if (this.status) this.removeStatus(e);

    if (e.value == "") {
      this.status = "TextInput--status--error";
    } else {
      this.status = "TextInput--status--success";
    }

    this.addStatus(e);
  }

  setTarget(e) {
    if (e.target) return e.target;
    else return e;
  }

  validatePassword(e) {
    e = this.setTarget(e);

    if (this.status) this.removeStatus(e);

    if (e.value == "" || e.value.length < 8) {
      this.status = "TextInput--status--error";
    } else {
      this.status = "TextInput--status--success";
    }

    this.addStatus(e);
  }

  validateRegExp(e, re) {
    e = this.setTarget(e);

    if (this.status) this.removeStatus(e);

    if (e.value == "" || !re.test(e.value)) {
      this.status = "TextInput--status--error";
    } else {
      this.status = "TextInput--status--success";
    }

    this.addStatus(e);
  }

  validateEmail(e) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.validateRegExp(e, re);
  }

  validatePhone(e) {
    const re = /^\+([0-9]{12})$/;

    this.validateRegExp(e, re);
  }

  removeStatus(el) {
    el.closest(".FieldGroup")
      .querySelector(".TextInput--status")
      .classList.remove(this.status);
  }

  addStatus(el) {
    el.closest(".FieldGroup")
      .querySelector(".TextInput--status")
      .classList.add(this.status);
  }

  togglePasswordVisibility(el) {
    if (el.target) el = el.target;

    if (el.checked) this.passwordTarget.setAttribute("type", "text");
    else this.passwordTarget.setAttribute("type", "password");
  }

  reset(e) {
    e.preventDefault();

    this.phonenumberTarget.value = "";
    this.addressTarget.value = "";
    this.zipcodeTarget.value = "";
    this.cityTarget.value = "";
    this.emailTarget.value = "";
    this.passwordTarget.value = "";
    this.membercardTarget.checked = false;
    this.newsletterTarget.checked = false;
  }
}
