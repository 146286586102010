import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    document.getElementById("sales_error").style.display = "none";
    this.confirm = false;
  }

  get amountController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("amount-field"),
      "amount"
    );
  }

  get ibanController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("iban-field"),
      "iban"
    );
  }

  sell_confirm(event) {
    this.confirm = event.target.checked;
  }

  submit(event) {
    let validity = this.checkValidity();

    if (!validity) {
      document.getElementById("sales_error").style.display = "block";
      event.preventDefault();
    }
  }

  checkValidity() {
    // Check amount & IBAN field validity
    return (
      this.amountController.isValid() &&
      this.ibanController.isValid() &&
      this.confirm
    );
  }
}
