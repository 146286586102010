import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["entitlement", "field"];

  initialize() {
    document.getElementById("initiative_error").style.display = "none";
  }

  get checkbox() {
    return this.entitlementTarget;
  }

  get fields() {
    return this.fieldTargets;
  }

  submit(event) {
    if (!this.checkbox.checked || !this.CheckIfBlank(this.fields)) {
      document.getElementById("initiative_error").style.display = "block";
      event.preventDefault();
    }
  }

  CheckIfBlank(fields) {
    for (var i = 0; i < fields.length; i++) {
      if (fields[i].value == "") {
        return false;
      }
    }
    return true;
  }
}
