import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  initialize() {
    document.getElementById("subscription_error").style.display = "none";
  }

  get checkbox() {
    return this.checkboxTargets;
  }

  get amountController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("amount-field"),
      "amount"
    );
  }

  get ibanController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("iban-field"),
      "iban"
    );
  }

  submit(event) {
    const checkboxes = this.checkbox;

    let validity = this.checkValidity(checkboxes);

    if (!validity) {
      document.getElementById("subscription_error").style.display = "block";
      event.preventDefault();
    }
  }

  checkValidity(cb) {
    // Check amount & IBAN field validity
    if (!this.checkFieldsValidity()) return false;

    // Check checkbox validity
    for (var i = 0; i < cb.length; i++) {
      if (!cb[i].checked) {
        return false;
      }
    }
    return true;
  }

  checkFieldsValidity() {
    return this.amountController.isValid() && this.ibanController.isValid();
  }
}
