const card_notice = {};

card_notice.init = () => {
  const qrButton = document.querySelector(".UserProfile .Card__FullSize");
  const qrButtonMobile = document.querySelector(".CardWrapper .Card__FullSize");

  if (qrButton) {
    qrButton.addEventListener("click", e => {
      card_notice.open();
    });
  }

  if (qrButtonMobile) {
    qrButtonMobile.addEventListener("click", e => {
      card_notice.open();
    });
  }
};

card_notice.close = (overlayEl, noticeEl) => {
  noticeEl.classList.remove("CardNotice--visible");
  noticeEl.classList.add("CardNotice--hide");
  overlayEl.classList.remove("CardNoticeOverlay--visible");
  overlayEl.classList.add("CardNoticeOverlay--hide");
};

card_notice.open = () => {
  const overlayEl = document.querySelector(".CardNoticeOverlay");
  const noticeEl = document.querySelector(".CardNotice");

  noticeEl.classList.remove("CardNotice--hide");
  noticeEl.classList.add("CardNotice--visible");
  overlayEl.classList.remove("CardNoticeOverlay--hide");
  overlayEl.classList.add("CardNoticeOverlay--visible");

  if (overlayEl !== null && noticeEl !== null) {
    const buttonEl = noticeEl.querySelector(".Button--close");

    if (buttonEl !== null) {
      noticeEl.querySelector(".Button--close").addEventListener("click", e => {
        e.preventDefault();
        card_notice.close(overlayEl, noticeEl);
      });
    }
  }
};

window.card_notice = card_notice;
