import { Controller } from "stimulus";

export default class CredentialsController extends Controller {
  static targets = ["email", "password", "submit"];
  static emailValidationRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  initialize() {
    this.deferTimeouts = {};
    this.initialValidations();
  }

  connect() {
    this.initialValidations();
  }

  // Validate field values in case they are initialized in non-empty state.
  initialValidations() {
    if (this.emailTarget.value && this.emailTarget.value !== "") {
      this.validateEmail(this.emailTarget, true);
    }
    if (this.passwordTarget.value && this.passwordTarget.value !== "") {
      this.validatePassword(this.passwordTarget);
    }
  }

  updateSubmitAvailability() {
    if (this.isValidForm()) {
      this.submitTarget.removeAttribute("disabled");
    } else {
      this.submitTarget.setAttribute("disabled", true);
    }
  }

  isValidForm() {
    return [this.emailTarget, this.passwordTarget].reduce((valid, target) => {
      if (target.closest(".TextInput--status--success") == null) {
        return false;
      } else {
        return valid;
      }
    }, true);
  }

  getTarget(e) {
    if (e.target) return e.target;
    else return e;
  }

  validatePassword(e, preserveServerErrors) {
    const validateNow = () => {
      this.validateField(this.getTarget(e), [
        this.notBlank,
        this.longerThan.bind(this, 8)
      ]);
    };

    if (preserveServerErrors) {
      return this.isValidFromServer(this.getTarget(e)) && validateNow();
    } else if (this.requiresImmediateAction(e)) {
      validateNow();
    } else {
      this.defer("password", () => {
        const serverError = this.getTarget(e)
          .closest(".FieldGroup")
          .querySelector(".ErrorText");
        serverError && serverError.classList.remove("ErrorText--visible");
        validateNow();
      });
    }
  }

  validateEmail(e, preserveServerErrors) {
    const validateNow = () => {
      this.validateField(this.getTarget(e), [
        this.notBlank,
        this.matches.bind(this, CredentialsController.emailValidationRe)
      ]);
    };

    if (preserveServerErrors) {
      return this.isValidFromServer(this.getTarget(e)) && validateNow();
    } else if (this.requiresImmediateAction(e)) {
      validateNow();
    } else {
      this.defer("email", () => {
        const serverError = this.getTarget(e)
          .closest(".FieldGroup")
          .querySelector(".ErrorText");
        serverError && serverError.classList.remove("ErrorText--visible");
        validateNow();
      });
    }
  }

  validateField(field, validations = []) {
    let validityStatus;
    this.removeStatus(field);

    const valid = validations.reduce((valid, fn) => {
      return valid && fn(field.value);
    }, true);
    if (valid) {
      validityStatus = "TextInput--status--success";
    } else {
      validityStatus = "TextInput--status--error";
    }

    this.addStatus(field, validityStatus);
  }

  requiresImmediateAction(e) {
    return (
      e.key === "Enter" ||
      e.keyCode === 13 ||
      (e.key === "Tab" || e.keyCode === 9) ||
      e.type === "change" ||
      e.type === "blur"
    );
  }

  notBlank(value) {
    return value && value !== "";
  }

  longerThan(num, value) {
    return value && value.length >= num;
  }

  matches(re, value) {
    return value && re.test(value);
  }

  isValidFromServer(target) {
    const serverError = target
      .closest(".FieldGroup")
      .querySelector(".ErrorText");

    const errorsExist =
      serverError &&
      (serverError.childElementCount > 0 ||
        (serverError.textContent && serverError.textContent.length > 0));
    return !errorsExist;
  }

  removeStatus(el) {
    el.closest(".FieldGroup")
      .querySelector(".TextInput--status")
      .classList.remove(
        "TextInput--status--error",
        "TextInput--status--success"
      );
  }

  addStatus(el, status) {
    el.closest(".FieldGroup")
      .querySelector(".TextInput--status")
      .classList.add(status);
    this.updateSubmitAvailability();
  }

  // Defers execution of given function for 500ms, used to debounce validations
  // in input fields
  defer(identifier, fn) {
    if (this.deferTimeouts[identifier]) {
      clearTimeout(this.deferTimeouts[identifier]);
    }
    this.deferTimeouts[identifier] = setTimeout(fn, 500);
    return this.deferTimeouts[identifier];
  }
}
