import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["feedback"];

  initialize() {}

  validate() {
    this.clearBackEndErrors(this.element);
  }

  clearBackEndErrors(el) {
    const error_status = el.querySelector(".TextInput--status");
    const error_info = el.querySelector(".ErrorText");
    if (error_status) {
      error_status.classList.remove("TextInput--status--error");
    }
    if (error_info) {
      error_info.classList.remove("ErrorText--visible");
    }
  }
}
