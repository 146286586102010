import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  initialize() {
    this.showTab();
  }

  change(e) {
    this.index = this.tabTargets.indexOf(e.target.parentNode);
    this.showTab();
  }

  showTab() {
    const profit_shares_elem = document.getElementById("profit_shares");
    const sold_profit_shares_elem = document.getElementById(
      "sold_profit_shares"
    );

    if (profit_shares_elem) {
      if (this.index == 0) {
        profit_shares_elem.style.display = "block";
        sold_profit_shares_elem.style.display = "none";
      } else if (this.index == 1) {
        profit_shares_elem.style.display = "none";
        sold_profit_shares_elem.style.display = "block";
      }
    }

    this.removeAndAddClasses();
  }

  removeAndAddClasses() {
    this.tabTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.add("ProfitShares__Page__Body__TabbedNavItem--active");
        el.classList.remove("ProfitShares__Page__Body__TabbedNavItem");
      } else if (this.index == -1) {
        return;
      } else {
        el.classList.add("ProfitShares__Page__Body__TabbedNavItem");
        el.classList.remove("ProfitShares__Page__Body__TabbedNavItem--active");
      }
    });
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
