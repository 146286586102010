import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "overlay"];

  initialize() {}

  close() {
    this.contentTarget.classList.add("Notice--hide");
    this.overlayTarget.classList.add("NoticeOverlay--hide");

    setTimeout(() => {
      if (this.hasContentTarget && this.contentTarget.parentNode) {
        this.contentTarget.parentNode.removeChild(this.contentTarget);
      }
      if (this.hasOverlayTarget && this.overlayTarget.parentNode) {
        this.overlayTarget.parentNode.removeChild(this.overlayTarget);
      }
    }, 750);
  }
}
