import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card", "confirm", "submit"];

  initialize() {
    setTimeout(() => {
      document.getElementsByClassName("CardQR--code")[0].style.display = "none";
      document.getElementsByClassName("CardQR--expired")[0].style.display =
        "block";

      if (document.querySelector(".UserProfile__MemberCard__QR") != null) {
        document.getElementsByClassName("CardQR--code")[0].style.display =
          "none";
        document.getElementsByClassName("CardQR--expired")[0].style.display =
          "block";
      }
    }, 900000);
  }

  toggle() {
    this.element.classList.toggle("CardWrapper--active");
  }

  refresh(e) {
    e.preventDefault();
    window.location.reload();
  }

  onConfirm() {
    const state = this.confirmTarget.checked;
    this.submitTarget.disabled = !state;
  }
}
